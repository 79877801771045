import './Hero.css'

function Hero() {
    return (
      <div className="Hero">
        <h2>Made with love by</h2>
        <h1>crunchy.works</h1>
      </div>
    );
  }
  
  export default Hero;
  